// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kernevaerdier-jsx": () => import("./../../../src/pages/kernevaerdier.jsx" /* webpackChunkName: "component---src-pages-kernevaerdier-jsx" */),
  "component---src-pages-kompetencer-jsx": () => import("./../../../src/pages/kompetencer.jsx" /* webpackChunkName: "component---src-pages-kompetencer-jsx" */),
  "component---src-pages-konsulentydelser-jsx": () => import("./../../../src/pages/konsulentydelser.jsx" /* webpackChunkName: "component---src-pages-konsulentydelser-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-om-keldgor-jsx": () => import("./../../../src/pages/omKeldgor.jsx" /* webpackChunkName: "component---src-pages-om-keldgor-jsx" */)
}

