module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Keldgor | Digitalisering","short_name":"Keldgor","start_url":"/","background_color":"#FFFFFF","theme_color":"#1f3050","display":"standalone","icon":"static/images/keldgor-fav.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3b7e0bb2e94d31d79e9d225202899226"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
